import React from "react";
import styles from "./Home.module.css";
import commonStyles from "../common/common.module.css";

import shakespeareLogo from "../../assets/images/shakespeare_logo.png";
import HeroCard from "./HeroCard";

import robotBadge from "../../assets/images/robot_badge.png";
import robotTarget from "../../assets/images/robot_target.png";
import robotAnalytics from "../../assets/images/robot_analytics.png";
import ContactForm from "../ContactForm/ContactForm";

const Home = () => {
	return (
		<>
			<div className={styles.page1}>
				<div className={styles.header}>
					<a href="https://shakespeare.vision">
						<img alt="Logo" className={styles.logo} src={shakespeareLogo} />
					</a>
					<h1
						className={[commonStyles.gradient_text, styles.heading].join(" ")}
					>
						World's first Metaverse & <br /> Artificial Intelligence{" "}
						<u>Advertising</u>
					</h1>
					<div
						className={styles.header_button}
						onClick={() => {
							const contactForm = document.getElementById("contact");
							contactForm.scrollIntoView({ behavior: "smooth" });
						}}
					>
						Get Started
					</div>
				</div>
				<div className={styles.hero_section}>
					<HeroCard
						image={robotBadge}
						title="Promote your Brand"
						description="Your personalized promotion crafted for the unique consumers who make the most out of our Metaverse ae Advertising"
					/>
					<HeroCard
						image={robotTarget}
						title="Target your Audience"
						description="Using our highly researched and tested Machine Learning models, we will help you streamline your target Audience by your nature of business."
					/>
					<HeroCard
						image={robotAnalytics}
						title="Advanced Analytics"
						description="Utilize our Advanced analytics to understand what your loyal customers love about you, and use our proven analytics & frameworks to increase your retention rate."
					/>
				</div>
				<div className={[styles.footer_text].join(" ")}>
					“For every advertising dollar spent
					<br />
					Shakespeare returns <b>8x</b> over the investment”
					<hr />
				</div>
			</div>
			<ContactForm />
		</>
	);
};

export default Home;
