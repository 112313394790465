import React from "react";
import styles from "./HeroCard.module.css";

const HeroCard = ({ children, ...props }) => {
	return (
		<div className={styles.card}>
			<img alt={props.title} className={styles.image} src={props.image} />
			<h1 className={styles.title}>{props.title}</h1>
			<div className={styles.description}>{props.description}</div>
		</div>
	);
};

export default HeroCard;
