import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

import styles from "./ContactForm.module.css";
import commonStyles from "../common/common.module.css";
import RotatingLinesLoader from "../common/RotatingLinesLoader";

const ContactForm = () => {
	const [inputs, setInputs] = useState({});
	const [tncChecked, setTncChecked] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showThankYou, setShowThankYou] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInputs({ ...inputs, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSubmitting(true);

		try {
			if (
				!inputs.name ||
				!inputs.company ||
				!inputs.companyDescription ||
				!inputs.email ||
				!inputs.phone ||
				!inputs.city
			) {
				toast.error("Please fill all the fields!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				setIsSubmitting(false);
				return;
			} else if (!tncChecked) {
				toast.error("Please accept the terms and conditions!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				setIsSubmitting(false);
				return;
			} else if (
				!inputs.email.match("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
			) {
				toast.error("Please enter a valid email address!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				setIsSubmitting(false);
				return;
			} else if (!inputs.phone.match("^[0-9]{10}$")) {
				toast.error("Please enter a valid phone number!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				setIsSubmitting(false);
				return;
			}

			await axios.post("/leads/advertising", inputs, {
				headers: {
					"x-auth-token": process.env.REACT_APP_MAIL_TOKEN,
				},
			});

			setInputs({});

			toast.success("Thank you for your interest!", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				draggable: true,
				pauseOnHover: false,
				theme: "colored",
			});

			setShowThankYou(true);
			setIsSubmitting(false);
		} catch (err) {
			toast.error("Something went wrong, please try again later.", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				draggable: true,
				pauseOnHover: false,
				theme: "colored",
			});
			setIsSubmitting(false);
		}
	};

	return (
		<div className={styles.body} id="contact">
			<div className={[styles.heading, commonStyles.gradient_text].join(" ")}>
				<h1>Get Started!</h1>
				<h2>
					Please leave your contact details here and we'll get back to you
					within 24 hours.
				</h2>
			</div>
			{showThankYou ? (
				<div className={styles.thank_you_block}>
					<h1>Thank you for letting us know your requirement!</h1>
					<h2>You will receive a confirmation email shortly.</h2>
				</div>
			) : (
				<div className={styles.form}>
					<div className={styles.form_title}>Please fill your Information:</div>
					<div className={styles.floating_label_group}>
						<input
							name="name"
							type="text"
							value={inputs.name || ""}
							required={true}
							onChange={handleChange}
						/>
						<label className={styles.floating_label}>Name</label>
					</div>
					<div className={styles.floating_label_group}>
						<input
							name="email"
							type="text"
							value={inputs.email || ""}
							required={true}
							onChange={handleChange}
						/>
						<label className={styles.floating_label}>Email</label>
					</div>
					<div className={styles.floating_label_group}>
						<input
							name="phone"
							type="number"
							min={0}
							maxLength={10}
							value={inputs.phone || ""}
							required={true}
							onChange={handleChange}
						/>
						<label className={styles.floating_label}>Phone</label>
					</div>
					<div className={styles.floating_label_group}>
						<input
							name="city"
							type="text"
							value={inputs.city || ""}
							required={true}
							onChange={handleChange}
						/>
						<label className={styles.floating_label}>City</label>
					</div>
					<div className={styles.floating_label_group}>
						<input
							name="company"
							type="text"
							required={true}
							onChange={handleChange}
						/>
						<label className={styles.floating_label}>Company</label>
					</div>
					<div className={styles.floating_label_group}>
						<textarea
							name="companyDescription"
							type="text"
							value={inputs.companyDescription || ""}
							required={true}
							style={{
								resize: "none",
								width: "calc(100% - 16px)",
								height: "100px",
								marginRight: "8px",
							}}
							onChange={handleChange}
						/>
						<label className={styles.floating_label}>Company Description</label>
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							flexDirection: "row",
						}}
					>
						<input
							type="checkbox"
							className={styles.input_field}
							checked={tncChecked}
							onChange={(e) => setTncChecked(e.target.checked)}
							style={{
								cursor: "pointer",
								display: "inline-block",
								width: "16px",
								marginRight: "8px",
							}}
						/>
						<span>
							I hereby accept all
							<a
								style={{
									textDecoration: "none",
									marginLeft: "4px",
								}}
								href="https://shakespeare.vision/terms"
								target="_blank"
							>
								terms and conditions
							</a>
						</span>
					</div>
					<button
						className={styles.submit_button}
						onClick={isSubmitting ? () => {} : handleSubmit}
					>
						{isSubmitting ? (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "row",
								}}
							>
								<RotatingLinesLoader width="20px" strokeColor="black" />
								&nbsp; Please Wait...
							</div>
						) : (
							"Submit"
						)}
					</button>
				</div>
			)}
		</div>
	);
};

export default ContactForm;
